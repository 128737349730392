import React from "react"

import RootLayout from "../components/layouts/RootLayout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <RootLayout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </RootLayout>
)

export default NotFoundPage
